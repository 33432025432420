body {
  background: var(--bg-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: var(--main-color);
  color:var(--color-title);
}

* {
  box-sizing: border-box;
}

p,
.title {
  margin: 0;
}

.title {
  color: var(--color-title);
}

p {
  font-size: 20px;
  color: var(--paragraph);
}

h1 {
  font-size: 35px;
}

h3 {
  font-size: 25px;
}

a {
  text-decoration: none;
}