// light theme
$light-bg-color: #F3F4F6;
$light-secondary-bg-color: #fff;
$light-grey-color: #d8d8df;
$light-colored-text: #4174d7;
$light-main-color: #66cc99;
$light-secondary-color: #E3C1F9;
$light-paragraph: #535569;
$light-color-title: #151b49;
$light-nav-bg-hover: lighten($light-color-title, 76%);
$light-nav-bg-active: lighten($light-color-title, 70%);
$light-error-color : #ff0000;
$light-error-bg : #f7dddd;
$light-error-bg-darker : lighten($light-error-color, 40%);
$light-warning-color : #ff9100;
$light-warning-bg : #FFF2E0;
$light-warning-bg-darker : #FFE0B8;
$light-success-color : #48a473;
$light-success-bg : lighten($light-success-color, 45%);
$light-success-bg-darker : lighten($light-success-color, 40%);

[data-theme="light"] {
  --bg-color: #{$light-bg-color};
  --secondary-bg-color: #{$light-secondary-bg-color};
  --grey-color: #{$light-grey-color};
  --colored-text: #{$light-colored-text};
  --main-color: #{$light-main-color};
  --secondary-color: #{$light-secondary-color};
  --paragraph: #{$light-paragraph};
  --color-title: #{$light-color-title};
  --nav-bg-hover: #{$light-nav-bg-hover};
  --nav-bg-active: #{$light-nav-bg-active};
  --error-color: #{$light-error-color};
  --error-bg: #{$light-error-bg};
  --error-bg-darker: #{$light-error-bg-darker};
  --warning-color: #{$light-warning-color};
  --warning-bg: #{$light-warning-bg};
  --warning-bg-darker: #{$light-warning-bg-darker};
  --success-color: #{$light-success-color};
  --success-bg: #{$light-success-bg};
  --success-bg-darker: #{$light-success-bg-darker};

  --toastify-color-light: #fff;
  --toastify-color-dark: #{$light-success-bg};
  --toastify-color-info: #3498db;
  --toastify-color-success: #{$light-success-color};
  --toastify-color-warning: #{$light-warning-color};
  --toastify-color-error: #{$light-error-color};
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: var(--toastify-color-success);
  --toastify-text-color-dark: var(--toastify-color-success);

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

//dark theme
$opacity-hover: 0.05;
$opacity-active: 0.1;
$dark-bg-color: #151419;
$dark-secondary-bg-color: #1f1d25;
$dark-grey-color: #292929;
$dark-colored-text: rgb(116, 137, 176);
$dark-main-color: #66cc99;
$dark-secondary-color: #E3C1F9;
$dark-paragraph: #ADADB0;
$dark-color-title: #fff;
$dark-nav-bg-hover: rgba($dark-colored-text, $opacity-hover);
$dark-nav-bg-active: rgba($dark-colored-text, $opacity-active);
$dark-error-color : rgb(255, 80, 80);
$dark-error-bg : #21181e;
$dark-error-bg-darker : rgba($dark-error-color, $opacity-active);
$dark-warning-color : #ffa52f;
$dark-warning-bg : #4B3826;
$dark-warning-bg-darker : #6B4B28;
$dark-success-color : rgb(72, 164, 115);
$dark-success-bg : #1f2527;
$dark-success-bg-darker : #1a1f21;

[data-theme="dark"] {
  --bg-color: #{$dark-bg-color};
  --secondary-bg-color: #{$dark-secondary-bg-color};
  --grey-color: #{$dark-grey-color};
  --colored-text: #{$dark-colored-text};
  --main-color: #{$dark-main-color};
  --secondary-color: #{$dark-secondary-color};
  --paragraph: #{$dark-paragraph};
  --color-title: #{$dark-color-title};
  --nav-bg-hover: #{$dark-nav-bg-hover};
  --nav-bg-active: #{$dark-nav-bg-active};
  --error-color: #{$dark-error-color};
  --error-bg: #{$dark-error-bg};
  --error-bg-darker: #{$dark-error-bg-darker};
  --warning-color: #{$dark-warning-color};
  --warning-bg: #{$dark-warning-bg};
  --warning-bg-darker: #{$dark-warning-bg-darker};
  --success-color: #{$dark-success-color};
  --success-bg: #{$dark-success-bg};
  --success-bg-darker: #{$dark-success-bg-darker};

  --toastify-color-light: #fff;
  --toastify-color-info: #3498db;
  --toastify-color-success: #{$dark-success-color} !important;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #{$dark-error-color} !important;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: #{$dark-success-color};
  --toastify-icon-color-warning: #{$dark-warning-color};
  --toastify-icon-color-error: #{$dark-error-color};

  --toastify-toast-width: 320px;
  --toastify-toast-background: red;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #{$dark-success-color};

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: #{$dark-success-color};
  --toastify-color-progress-warning: #{$dark-warning-color};
  --toastify-color-progress-error: #{$dark-error-color};
}

:root{
  --max-width-container: 1500px ;
  --main-bottom-padding: 80px ;
}

body.no-scroll {
  overflow: hidden;
}

.Toastify__toast {
  box-shadow: none !important;
}

.Toastify__toast--error {
  background: var(--error-bg) !important;
  color: var(--error-color) !important;
}

.Toastify__toast--warning {
  background: var(--warning-bg) !important;
  color: var(--warning-color) !important;
}

.Toastify__toast--success {
  background: var(--success-bg) !important;
  color: var(--success-color) !important;
}

input[type=text],
input[type=number],
input[type=password],
input[type=email],

input[type=text]:-webkit-autofill,
input[type=number]:-webkit-autofill,
input[type=password]:-webkit-autofill,
input[type=email]:-webkit-autofill,

input[type=text]:-webkit-autofill:hover,
input[type=number]:-webkit-autofill:hover,
input[type=password]:-webkit-autofill:hover,
input[type=email]:-webkit-autofill:hover,

input[type=text]:-webkit-autofill:focus,
input[type=number]:-webkit-autofill:focus,
input[type=password]:-webkit-autofill:focus,
input[type=email]:-webkit-autofill:focus,

input[type=text]:-webkit-autofill:active,
input[type=number]:-webkit-autofill:active,
input[type=password]:-webkit-autofill:active,
input[type=email]:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-bg-color) inset !important;
  box-shadow: 0 0 0 30px var(--secondary-bg-color) inset !important;
  -webkit-text-fill-color: var(--color-title) !important;
  color: var(--color-title) !important;
  background: var(--secondary-bg-color);
}

aside::-webkit-scrollbar {
  background: var(--bg-color);
}

aside::-webkit-scrollbar-thumb {
  background: var(--bg-color);
}

aside:hover::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

::selection {
  background-color: var(--main-color);
  color: var(--bg-color);
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--main-color);
  border-radius: 50px;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--main-color);
}


fieldset {
  color: var(--color-title);
  display: flex;
  gap: 30px;
}

.bloc-1{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.navigation{
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.menu {
  margin: 0;
  border-bottom: solid 1px var(--grey-color);
  padding: 10px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

  .profil-container {
    left: 20px;
    bottom: 20px;
    z-index: 10;
    padding-top: 0px;
    position: absolute;
    display: flex;
    padding: 8px 20px;
    border-radius: 50px;
    align-items: center;
    gap: 20px;

    .user-picture {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 2px solid var(--color-title);
      object-fit: cover;
    }
  }

  .big-user-picture {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    object-fit: cover;
  }


  .user-container {
    width: fit-content;
    display: flex;
    padding: 8px 20px;
    border-radius: 50px;
    gap: 10px;
    align-items: center;
    background: var(--secondary-bg-color);
    z-index: 1;

    .user-picture {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      object-fit: cover;
    }
  }

  .user-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--color-title);
    pointer-events: fill;
    object-fit: cover;
  }


.theme-container {
  display: flex;
  position: relative;
}

.radio-theme {
  margin-left: 0;
  margin-right: 0;
  left: 15px;
  position: absolute;
  bottom: 12px;
  accent-color: var(--success-color);
  background: none;
}

.label-theme {
  border: solid grey 2px;
  border-radius: 5px;
  padding-bottom: 8px;
  cursor: pointer;
}

:checked+.label-theme {
  border: solid var(--success-color) 2px;
  border-radius: 5px;
  padding-bottom: 8px;
  cursor: pointer;
}

.text-theme {
  margin-left: 40px;
  color: var(--paragraph);
}

.button-container {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.dialog-content {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.image-container {
  position: absolute;
  max-width: 250px;
  max-height: 250px;
  bottom: -30px;
  left: 30px;
}

.test{
  margin: 0;
  line-height: 1;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 100px;
  z-index: 5;
}
